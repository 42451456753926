// Generated by CoffeeScript 1.10.0
(function() {
  var DoubleLinkedList, LRUCache, createNode;

  createNode = function(data, pre, next) {
    return {
      data: data,
      pre: pre,
      next: next
    };
  };

  DoubleLinkedList = (function() {
    function DoubleLinkedList() {
      this.headNode = this.tailNode = null;
    }

    DoubleLinkedList.prototype.remove = function(node) {
      if (node.pre) {
        node.pre.next = node.next;
      } else {
        this.headNode = node.next;
      }
      if (node.next) {
        return node.next.pre = node.pre;
      } else {
        return this.tailNode = node.pre;
      }
    };

    DoubleLinkedList.prototype.insertBeginning = function(node) {
      if (this.headNode) {
        node.next = this.headNode;
        this.headNode.pre = node;
        return this.headNode = node;
      } else {
        return this.headNode = this.tailNode = node;
      }
    };

    DoubleLinkedList.prototype.moveToHead = function(node) {
      this.remove(node);
      return this.insertBeginning(node);
    };

    DoubleLinkedList.prototype.clear = function() {
      return this.headNode = this.tailNode = null;
    };

    return DoubleLinkedList;

  })();

  LRUCache = (function() {
    function LRUCache(capacity, maxAge) {
      this.capacity = capacity != null ? capacity : 10;
      this.maxAge = maxAge != null ? maxAge : 60000;
      this._linkList = new DoubleLinkedList();
      this.reset();
    }

    LRUCache.prototype.keys = function() {
      return Object.keys(this._hash);
    };

    LRUCache.prototype.values = function() {
      var values;
      values = this.keys().map((function(_this) {
        return function(key) {
          return _this.get(key);
        };
      })(this));
      return values.filter(function(v) {
        return v !== void 0;
      });
    };

    LRUCache.prototype.remove = function(key) {
      var node;
      if (this._hash[key] != null) {
        node = this._hash[key];
        this._linkList.remove(node);
        delete this._hash[key];
        if (node.data.onDispose) {
          node.data.onDispose.call(this, node.data.key, node.data.value);
        }
        return this.size--;
      }
    };

    LRUCache.prototype.reset = function() {
      this._hash = {};
      this.size = 0;
      return this._linkList.clear();
    };

    LRUCache.prototype.set = function(key, value, onDispose) {
      var node;
      node = this._hash[key];
      if (node) {
        node.data.value = value;
        node.data.onDispose = onDispose;
        return this._refreshNode(node);
      } else {
        if (this.size === this.capacity) {
          this.remove(this._linkList.tailNode.data.key);
        }
        node = createNode({
          key: key,
          value: value,
          onDispose: onDispose
        });
        node.data.lastVisitTime = Date.now();
        this._linkList.insertBeginning(node);
        this._hash[key] = node;
        this.size++;
      }
    };

    LRUCache.prototype.get = function(key) {
      var node;
      node = this._hash[key];
      if (!node) {
        return void 0;
      }
      if (this._isExpiredNode(node)) {
        this.remove(key);
        return void 0;
      }
      this._refreshNode(node);
      return node.data.value;
    };

    LRUCache.prototype._refreshNode = function(node) {
      node.data.lastVisitTime = Date.now();
      return this._linkList.moveToHead(node);
    };

    LRUCache.prototype._isExpiredNode = function(node) {
      return Date.now() - node.data.lastVisitTime > this.maxAge;
    };

    LRUCache.prototype.has = function(key) {
      return this._hash[key] != null;
    };

    return LRUCache;

  })();

  if (typeof module === 'object' && module.exports) {
    module.exports = LRUCache;
  } else {
    this.LRUCache = LRUCache;
  }

}).call(this);
